import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  FormState,
  FormStatus,
} from '../../../../utils/state/initialStateFactory';
import { ErrorHandlers } from '../errorHandlers/errorHandlers';

export type CalculatePaymentDetails = ({
  couponCode,
}: {
  couponCode?: string;
}) => Promise<void>;

export function createCalculatePaymentDetailsAction({
  actionFactoryParams,
  errorHandlers,
}: {
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>;
  errorHandlers: ErrorHandlers;
}): CalculatePaymentDetails {
  return async ({ couponCode }) => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { formApi, reportError } = actionFactoryParams.context;
    const {
      formInputs: { numberOfParticipants, email },
      slotAvailability,
      service,
    } = state;
    const { id: serviceId, rate } = service;
    const slot = slotAvailability?.slot!;

    setState({
      status: FormStatus.PROCESSING_PAYMENT_DETAILS,
    });

    try {
      const paymentsDetails = await formApi.getPaymentsDetails({
        slot,
        numberOfParticipants,
        rate,
        serviceId,
        couponCode,
        email,
      });
      const appliedCoupon = paymentsDetails?.couponDetails;
      const finalPrice = paymentsDetails?.finalPrice;

      setState({
        couponInfo: {
          ...state.couponInfo,
          appliedCoupon,
        },
        paymentDetails: {
          ...state.paymentDetails,
          minCharge: finalPrice?.downPayAmount
            ? Number(finalPrice.downPayAmount)
            : state.paymentDetails.minCharge,
          totalPrice: finalPrice?.amount
            ? Number(finalPrice?.amount)
            : state.paymentDetails.price,
        },
        status: FormStatus.IDLE,
      });
    } catch (error) {
      errorHandlers.addError(error);
      setState({ status: FormStatus.IDLE });
      reportError(error);
    }
  };
}
